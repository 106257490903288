import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HourlyWeekData } from "~/api/v1/ReportData/index.js";
import { queryFnWeek, WeeklyDataOptions, WeeklyGateDataOptions } from "./week.js";
import { multiPlaceholder } from "./multi.js";

export function useBinnedGateHackData({
  spaceId,
  gateId,
  enabled: enabledOption,
  date,
}: WeeklyGateDataOptions): UseQueryResult<HourlyWeekData> {
  const queryKey = ["Spaces", spaceId, "Gates", gateId, "BinnedDataHack", date.toString()];
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId && !!gateId;
  const staleTime = 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn: queryFnWeek<HourlyWeekData>, // TODO mix w/ queryFnSingle
    staleTime,
  });
}

export function useAllBinnedGateHackData({
  spaceId,
  enabled: enabledOption,
  date,
}: WeeklyDataOptions): UseQueryResult<Record<string, HourlyWeekData>> {
  const queryKey = ["Spaces", spaceId, "Gates", multiPlaceholder, "BinnedDataHack", date.toString()];
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const staleTime = 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn: queryFnWeek<Record<string, HourlyWeekData>>, // TODO mix w/ queryFnMulti
    staleTime,
  });
}

export function useBinnedGatePresenceData({
  spaceId,
  enabled: enabledOption,
  date,
}: WeeklyDataOptions): UseQueryResult<HourlyWeekData> {
  const queryKey = ["Spaces", spaceId, "Gates", multiPlaceholder, "BinnedPresence", date.toString()];
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const staleTime = 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn: queryFnWeek<HourlyWeekData>,
    staleTime,
  });
}
