
import type { SpaceData } from "~/api/v1/Space";

type Response = string | null | undefined; // null = no link, undefined = not ready

export default function getNeighbors(
  space: SpaceData | undefined,
  others: SpaceData[] | undefined,
): [Response, Response] {
  if (space === undefined || others === undefined)
    return [undefined, undefined]; // Not ready

  const siblings = others.filter((other) => other.location.type == space.location.type
      //&& other.super_space == space.super_space // Needs locationindex branch
      && other.identifier !== "35217e6c-7f83-4062-be4e-5e55f476f597" // rommelkist rechts, which should become a subspace so it gets filtered by the above
  );

  const ownIndex = siblings.findIndex((sibling) => sibling.identifier == space.identifier);
  if (ownIndex == -1)
    console.error(`This ${space.identifier} space was not found in the spaces we have access to??`, siblings);

  if (ownIndex === undefined)
    return [undefined, undefined]; // Not ready
  else if (ownIndex == -1)
    return [null, null]; // No links

  const left = ownIndex == 0
    ? null // No link
    : siblings[ownIndex-1].identifier;
  const right = ownIndex == siblings.length - 1
    ? null // No link
    : siblings[ownIndex+1].identifier;

  return [left, right];
}
