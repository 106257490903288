import { hour, minute } from "msecs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { SpaceData } from "../../../api/v1/Space/space.js";
import {
  OCCUPATION_STATES,
  OccupationState,
  UserRole,
} from "../../../constants.js";
import useHourlyWeekDataGrid from "~/hooks/useHourlyWeekDataGrid.js";
import {
  useBinnedGatePresenceData,
  usePeakOccupancyData,
  useSpaces,
  useSpaceGates,
  useSpaceGatesLatestData,
} from "~/hooks/api";
import AuthService from "../../../services/AuthService.js";
import { Col, Row } from "../../../styles/Grid.js";
import {
  formatHourMinute,
  parseHourMinute,
  startOfWeek,
} from "../../../utils/date-time.js";
import getNeighbors from "~/utils/getNeighbors.js";
import { maximumReducer, minimumReducer } from "../../../utils/math.js";
import useWebSocketData from "../../../websockets/useWebSocketData.js";
import {
  //LocationOccupationData,
  LiveEventData,
} from "../../../websockets/websockets.js";
import DeviceList from "../../Dashboard/DeviceList/DeviceList.js";
import HeatMap from "~/components/Dashboard/HeatMap/HeatMap.js";
import HourlyPresence from "../../Dashboard/HourlyPresence/HourlyPresence.js";
import LiveEvents from "../../Dashboard/LiveEvents/LiveEvents.js";
import LiveEventsCumulative from "../../Dashboard/LiveEventsCumulative/LiveEventsCumulative.js";
import MiniInfoCardCapacity from "../../Dashboard/MiniInfoCardCapacity/MiniInfoCardCapacity.js";
import PopularTimes from "../../Dashboard/PopularTimes/PopularTimes.js";
import ArrowButton from "../../UI/ArrowButton/ArrowButton.js";
import Button from "../../UI/Button/Button.js";
import DateSliderWeek from "../../UI/DateSliderWeek/DateSliderWeek.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import ValueBlock from "../../atoms/ValueBlock/ValueBlock.js";
import Header from "../../portal/HeaderNew/Header.js";
import StyledDashboard from "./Dashboard.styles.js";

type DashboardProps = {
  id: string;
  name: string;
  type?: string;
  data: SpaceData;
  deleteAction?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Dashboard: React.FC<DashboardProps> = ({
  id,
  name,
  type,
  data,
  deleteAction,
}: DashboardProps) => {
  const spaceId = id;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [initialOccupancy, setInitialOccupancy] = useState<number>(null);

  const organizationId = data?.organization?.identifier;
  const canEdit = AuthService.hasRole(UserRole.Admin);

  const [startOfWeekDate, setStartOfWeekDate] = useState<string>(null);

  const { data: spaces } = useSpaces({});

  const minimumOpeningClosingTime = 0 * hour;
  const maximumOpeningClosingTime = 24 * hour - 1 * minute;

  let openingTime = data.opening_hours
    .map((oh) => oh.opening_time)
    .map(parseHourMinute)
    .reduce(minimumReducer, maximumOpeningClosingTime);
  if (openingTime === maximumOpeningClosingTime) {
    openingTime = minimumOpeningClosingTime;
  }

  let closingTime = data.opening_hours
    .map((oh) => oh.closing_time)
    .map(parseHourMinute)
    .reduce(maximumReducer, minimumOpeningClosingTime);
  if (closingTime === minimumOpeningClosingTime) {
    closingTime = maximumOpeningClosingTime;
  }

  const openingHour = Math.floor(openingTime / hour);
  const closingHour = Math.floor(closingTime / hour);

  const openingTimeFormatted = formatHourMinute(openingTime);
  const closingTimeFormatted = formatHourMinute(closingTime);

  //const methodName = `lococc-${spaceId}`;
  const methodName = `liveevents-${spaceId}`;
  //const wsData = useWebSocketData<LocationOccupationData>(methodName);
  const wsData = useWebSocketData<LiveEventData>(methodName, null, organizationId);

  //const counterState = OCCUPATION_STATES[wsData?.state] ?? OccupationState.Ok;
  //const counterNumber = wsData?.curOccupation ?? 0;
  //const counterMaxNumber = wsData?.maxOccupation ?? data.capacity;
  const counterState = OccupationState.Ok;
  const counterNumber = Math.max(wsData?.CurrentOccupancy ?? initialOccupancy ?? 0, 0);
  const counterMaxNumber = wsData?.MaxOccupancy ?? data.capacity;

  const updateHourlyPresence = (dateTime: Date) => {
    setStartOfWeekDate(startOfWeek(dateTime).toISOString());
  };

  const { data: gates } = useSpaceGates({ spaceId });
  const { data: gatesLatestData } = useSpaceGatesLatestData({
    spaceId,
    queryClient,
  });

  const { data: binnedGatePresenceData } = useBinnedGatePresenceData({
    spaceId,
    enabled: gates?.length > 0,
    date: new Date(startOfWeekDate).getTime(),
  });

  const { data: peakOccupancyData } = usePeakOccupancyData({
    spaceId,
    enabled: gates?.length == 0,
    date: new Date(startOfWeekDate).getTime(),
  });

  // should fall back to PeopleCounterData-based stuff if no gates?
  // at least until we migrate terabee away from PeopleCounterData
  const hourlyWeekDataGrid = useHourlyWeekDataGrid({
    data: binnedGatePresenceData ?? peakOccupancyData,
    openingHour,
    closingHour,
  });

  useEffect(() => {
    if (initialOccupancy !== null)
      return;
    if (gatesLatestData === undefined)
      return;
    setInitialOccupancy(
      Object.entries(gatesLatestData)
        .reduce((acc, [gateId, gateData]) =>
          gateData.in - gateData.out
        , 0)
    );
  }, [initialOccupancy, gatesLatestData]);

  const [leftArrow, rightArrow] = getNeighbors(data, spaces);

  return (
    <>
      <Header>
        <div
          className="flex flex-row items-center"
          style={{ columnGap: "24px" }}
        >
          <ArrowButton
            disabled={leftArrow === null}
            href={leftArrow && `/locations/${leftArrow}/dashboard`}
            mirror={true}
          />

          <h2>
            {name}
          </h2>
        </div>

        <div
          className="flex flex-row items-center"
          style={{ columnGap: "16px" }}
        >
          {canEdit && (
            <Button
              label={t("locations.dashboard.header.button")}
              url={"/locations/" + spaceId + "/edit"}
            />
          )}

          <ArrowButton
            disabled={rightArrow === null}
            href={rightArrow && `/locations/${rightArrow}/dashboard`}
          />
        </div>
      </Header>
      <StyledDashboard>
        <Row>
          <Col cols={{ lg: 1, xl: 9 / 12 }} className="infoCards pushRight">
            <Row>
              <Col cols={{ lg: 1 }} className="locationInfoBlock">
                <BlockWrapper>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      columnGap: "32px",
                    }}
                  >
                    <ValueBlock
                      label={t("locations.form.capacity")}
                      value={data.capacity}
                    />
                    <ValueBlock
                      label={t("locations.form.areaSize")}
                      value={data.area_size}
                    />
                    <ValueBlock
                      label={t("locations.form.openingTime")}
                      value={openingTimeFormatted}
                    />
                    <ValueBlock
                      label={t("locations.form.closingTime")}
                      value={closingTimeFormatted}
                    />
                  </div>
                </BlockWrapper>
              </Col>
            </Row>
            <Row>
              <Col cols={{ lg: 1, xl: 8 / 12 }} className="infoCards pushRight">
                <DateSliderWeek onChange={updateHourlyPresence} />
                <HourlyPresence
                  headerName={gates?.length > 0 ? "HourlyGatePresence" : "HourlyPresence"}
                  data={hourlyWeekDataGrid}
                />
                <HeatMap />
              </Col>
              <Col cols={{ lg: 1, xl: 4 / 12 }} className="infoCards">
                <PopularTimes
                  spaceId={spaceId}
                  useGates={gates?.length > 0}
                  startTime={openingTimeFormatted}
                  endTime={closingTimeFormatted}
                />
                <MiniInfoCardCapacity
                  count={counterNumber}
                  maxCount={counterMaxNumber}
                  state={counterState}
                />
                <DeviceList organizationId={data.organization?.identifier} spaceId={spaceId} initialDevices={data.devices} />
              </Col>
            </Row>
          </Col>

          <Col cols={{ lg: 1, xl: 3 / 12 }} className="infoCards">
            <BlockWrapper>
              <Row>
                <Col cols={{ md: 1 }}>
                  <h2>{t("locations.dashboard.header.liveEvents")}</h2>
                </Col>
              </Row>
            </BlockWrapper>
            <LiveEvents organizationId={organizationId} spaceId={spaceId} initialOccupancy={initialOccupancy} />
            <LiveEventsCumulative organizationId={organizationId} spaceId={spaceId} useGates={gates?.length > 0} />
          </Col>
        </Row>
      </StyledDashboard>
    </>
  );
};

export default Dashboard;
